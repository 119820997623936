<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage scholarship</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Scholarship
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                    @click="addScholarship"
                    v-if="checkIsAccessible('scholarship', 'create')"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    scholarship </v-btn
                  >&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field
                    class="form-control"
                    v-model="search.title"
                    label="Search by title"
                    outlined
                    clearable
                    v-on:keyup.enter="getScholarships()"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="search.program_id"
                    :items="programs"
                    item-text="title"
                    item-value="id"
                    clearable
                    v-on:keyup.enter="getScholarships()"
                  >
                    <template v-slot:label> Program </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="2">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="search.academic_year_id"
                    :items="academic_years"
                    item-text="title"
                    item-value="id"
                    clearable
                    v-on:keyup.enter="getScholarships()"
                  >
                    <template v-slot:label> Academic year </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    outlined
                    dense
                    v-model="search.scholarship_type"
                    :items="scholarshipTypes"
                    item-text="name"
                    item-value="value"
                    clearable
                    v-on:keyup.enter="getScholarships()"
                  >
                    <template v-slot:label>
                      Scholarship type <span class="text-danger"></span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    :loading="loading"
                    @click.prevent="searchscholarships()"
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Description</th>
                    <th class="px-3 wrap-column">More information</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="scholarships.length > 0"
                    v-for="(item, index) of scholarships"
                    :key="index"
                  >
                    <td class="px-3 wrap-column">
                      {{ item.title }}
                    </td>
                    <td class="px-3 wrap-column">
                      <div v-if="item.description" v-html="item.description"></div>
                      <div v-else>NA</div>
                    </td>
                    
                    
                    <td class="px-3 wrap-column">
                      <div>
                        <strong> Scholarship type : </strong>
                        {{ item.scholarship_type_text }}
                      </div>

                      <div class="mt-2">
                        <strong> Applies to : </strong>
                        {{ item.applies_to_text }}
                      </div>

                      <div class="mt-2">
                        <strong>Academic year:</strong>
                        {{ item.academic_year_title ? item.academic_year_title : "-" }}
                      </div>

                      <div class="mt-2">
                        <strong>Program:</strong>
                        {{ item.program_title ? item.program_title : "-" }}
                      </div>
                    </td>
                    <td class="pr-0 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('scholarship', 'edit')">
                            <a
                              href="#"
                              class="navi-link"
                              @click="editScholarship(item.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('scholarship', 'delete')">
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteScholarship(item.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-delete"></i>
                              </span>
                              <span class="navi-text"> Delete </span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="scholarships.length == 0">
                    <td class="text-center px-3" colspan="7">Data not available</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-show="scholarships.length > 0"
                @input="getScholarships"
                class="pull-right mt-7"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <create-and-update ref="scholarship" @refresh="getScholarships"></create-and-update>
  </v-app>
</template>

<script>
import ScholarshipService from "@/core/services/scholarship/ScholarshipService";
import CreateAndUpdate from "./CreateAndUpdate";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const scholarship = new ScholarshipService();
const program = new ProgramService();
const academicYear = new AcademicYearService();

export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      search: {
        title: "",
        program_id: null,
        academic_year_id: null,
        scholarship_type: "",
      },
      loading: false,
      scholarships: [],
      page: null,
      perPage: null,
      total: null,
      programs: [],
      academic_years: [],
      scholarshipTypes: [
        {
          name: "Percentage",
          value: "percentage",
        },
        {
          name: "Fixed",
          value: "fixed",
        },
      ],
    };
  },
  methods: {
    searchscholarships() {
      this.page = 1;
      this.getScholarships();
    },
    getScholarships() {
      this.loading = true;
      scholarship
        .paginate(this.search, this.page)
        .then((response) => {
          this.scholarships = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addScholarship() {
      this.$refs["scholarship"].showModal();
    },
    editScholarship(id) {
      this.$refs["scholarship"].showModal(id);
    },
    deleteScholarship(id) {
      this.$confirm({
        message: `Are you sure you want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            scholarship
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully deleted");
                this.getScholarships();
              })
              .catch((error) => {});
          }
        },
      });
    },
    getPrograms() {
      program
        .all()
        .then((res) => {
          this.programs = res.data;
        })
        .catch((err) => {})
        .finally(() => {});
    },
    getAcademicYears() {
      academicYear
        .all()
        .then((res) => {
          this.academic_years = res.data;
        })
        .catch((err) => {})
        .finally(() => {});
    },
  },
  mounted() {
    this.getScholarships();
    this.getPrograms();
    this.getAcademicYears();
  },
};
</script>
