<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} scholarship` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="scholarship.title"
                                outlined
                                dense
                                :error="$v.scholarship.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.scholarship.title.$error">This information is required</span>
                        </v-col>
                        <v-col cols="12">
                            <label>
                                Description <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="scholarship.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.scholarship.description.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="scholarship.program_id"
                                :items="programs"
                                item-text="title"
                                item-value="id"
                                :error="$v.scholarship.program_id.$error"
                            >
                                <template v-slot:label>
                                    Program <span class="text-danger"></span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.scholarship.program_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                type="number"
                                outlined
                                dense
                                v-model="scholarship.no_of_students"
                                :error="$v.scholarship.no_of_students.$error"
                            >
                                <template v-slot:label>
                                    No of students <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.scholarship.no_of_students.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="scholarship.academic_year_id"
                                :items="academic_years"
                                item-text="title"
                                item-value="id"
                                :error="$v.scholarship.academic_year_id.$error"
                            >
                                <template v-slot:label>
                                    Academic year <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.scholarship.academic_year_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="scholarship.scholarship_type"
                                :items="scholarshipTypes"
                                item-text="name"
                                item-value="value"
                                :error="$v.scholarship.scholarship_type.$error"
                            >
                                <template v-slot:label>
                                    Scholarship type <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.scholarship.scholarship_type.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                type="number"
                                outlined
                                dense
                                v-model="scholarship.scholarship_amount"
                                :error="$v.scholarship.scholarship_amount.$error"
                            >
                                <template v-slot:label>
                                    Scholarship amount <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.scholarship.scholarship_amount.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                outlined
                                dense
                                v-model="scholarship.applies_to"
                                :items="appliesToLists"
                                item-text="name"
                                item-value="value"
                                :error="$v.scholarship.applies_to.$error"
                            >
                                <template v-slot:label>
                                    Applies to <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.scholarship.applies_to.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="hideModal"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn
                                    v-if="checkIsAccessible('gate-pass', 'create')"
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('scholarship', 'create') || checkIsAccessible('scholarship', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import ScholarshipService from "@/core/services/scholarship/ScholarshipService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const scholarship = new ScholarshipService();
const program = new ProgramService();
const academicYear = new AcademicYearService();

export default {
    validations: {
        scholarship: {
            title: { required },
            description: {},
            program_id: {},
            no_of_students: {required},
            academic_year_id: {required},
            scholarship_type: {required},
            scholarship_amount: {required},
            applies_to: {required},
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            scholarship: {
                title: '',
                description: '',
                program_id: null,
                no_of_students: '',
                academic_year_id: null,
                scholarship_type: '',
                scholarship_amount: '',
                applies_to: ''
            },
            programs:[],
            academic_years: [],
            scholarshipTypes:[
                {
                    name: 'Percentage',
                    value: 'percentage'
                },
                {
                    name: 'Fixed',
                    value: 'fixed'
                }
            ],
            appliesToLists:[
                {
                    name: 'Whole program',
                    value: 'whole_program'
                },
                {
                    name: 'Each semester',
                    value: 'each_semester'
                },
                {
                    name: 'First semester only',
                    value: 'first_semester_only'
                },
            ],
            errors: []
        };
    },
    methods: {
        showModal(id = null) {
            this.resetForm();
            if (id) {
                this.edit = true
                this.getScholarship(id)
            }
            this.getPrograms();
            this.getAcademicYears();
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
            this.resetForm();
        },
        getScholarship(id) {
            scholarship
            .show(id)
            .then(response => {
                this.scholarship = response.data.scholarship;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.scholarship.$touch();
            if (this.$v.scholarship.$error) {
                setTimeout(() => {
                    this.$v.scholarship.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateScholarship();
                } else {
                    this.createScholarship(this.scholarship);
                }
            }
        },
        updateScholarship() {
            this.isBusy = true;
            scholarship
            .update(this.scholarship.id, this.scholarship)
            .then(response => {
                this.isBusy = false;
                this.$emit("refresh");
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createScholarship() {
            this.isBusy = true;
            scholarship
            .store(this.scholarship)
            .then((response) => {
                this.isBusy = false;
                this.$emit("refresh");
                this.$snotify.success("Information added");
                this.hideModal();
            })
            .catch(error => {
                this.errors = error.errors;
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.scholarship.$reset();
            this.edit = false;
            this.scholarship = {
                title: '',
                description: '',
                program_id: null,
                no_of_students: '',
                academic_year_id: null,
                scholarship_type: '',
                scholarship_amount: '',
                applies_to: ''
            };
        },
        getPrograms(){
            program
            .all()
            .then((res) => {
                this.programs = res.data;
            })
            .catch(err => {

            })
            .finally(() => {

            });
        },
        getAcademicYears(){
            academicYear
            .all()
            .then((res) => {
                this.academic_years = res.data;
            })
            .catch(err => {

            })
            .finally(() => {

            });
        }
    }
}
</script>
